// src/components/LandingPage.js
import React, { useState, useContext } from 'react';
import './LandingPage.css';
import { useNavigate } from 'react-router-dom';
import HubSpotFormModal from './HubSpotFormModal';
import { SurveyContext } from '../contexts/SurveyContext';

function LandingPage() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { hasSubmittedSurvey } = useContext(SurveyContext);

  const handleRegisterInterest = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleTryFreeTool = () => {
    if (hasSubmittedSurvey) {
      navigate('/free-tool');
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <div className="landing-page">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>Revolutionise Pharma Compliance</h1>
          <p>Automate regulatory compliance with our AI-powered solution.</p>
          <button className="register-button" onClick={handleRegisterInterest}>
            Register Interest
          </button>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="features">
        <h2>Key Features</h2>
        <div className="features-list">
          <div className="feature-item">
            <h3>AI-Powered Analysis</h3>
            <p>Leverage AI to ensure compliance with FDA guidelines.</p>
          </div>
          <div className="feature-item">
            <h3>Real-Time Monitoring</h3>
            <p>Stay updated with the latest regulatory changes.</p>
          </div>
          <div className="feature-item">
            <h3>Actionable Insights</h3>
            <p>Receive detailed reports with recommendations.</p>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section id="how-it-works" className="how-it-works">
        <h2>How It Works</h2>
        <ol>
          <li>Upload your compliance documents.</li>
          <li>Our AI analyses them against current regulations.</li>
          <li>Receive a comprehensive compliance report.</li>
        </ol>
      </section>

      {/* Free Compliance Tool Section */}
      <section className="free-tool">
        <h2>Try Our Free Compliance Checker</h2>
        <p>Experience our AI capabilities with a free analysis.</p>
        <button className="try-free-tool-button" onClick={handleTryFreeTool}>
          Try It Now
        </button>
      </section>

      {/* Modal for HubSpot Form */}
      <HubSpotFormModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
}

export default LandingPage;
