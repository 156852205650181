// src/components/Header.js
import React, { useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from '../assets/images/BedrockCompliance.png';
import { FaBars, FaTimes } from 'react-icons/fa'; // Importing icons for the hamburger menu

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80; // Adjust based on header height
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="header-content">
        <Link to="/" onClick={closeMobileMenu}>
          <img src={logo} alt="Bedrock Compliance Logo" className="logo" />
        </Link>
        <nav className={`nav-menu ${isMobileMenuOpen ? 'active' : ''}`}>
          <HashLink smooth to="/#features" scroll={scrollWithOffset} onClick={closeMobileMenu}>
            Features
          </HashLink>
          <HashLink smooth to="/#how-it-works" scroll={scrollWithOffset} onClick={closeMobileMenu}>
            How It Works
          </HashLink>
          <HashLink smooth to="/#contact" scroll={scrollWithOffset} onClick={closeMobileMenu}>
            Contact
          </HashLink>
        </nav>
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </div>
    </header>
  );
}

export default Header;
