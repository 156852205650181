// src/App.js
import React from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingPage from './components/LandingPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FreeTool from './components/FreeTool'; // Ensure this component exists
import './firebase';
import { SurveyProvider } from './contexts/SurveyContext';
import ProtectedRoute from './components/ProtectedRoute'; // We'll create this next

function App() {
  return (
    <SurveyProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route 
            path="/free-tool" 
            element={
              <ProtectedRoute>
                <FreeTool />
              </ProtectedRoute>
            } 
          />
          {/* Add more routes as needed */}
        </Routes>
        <Footer />
      </Router>
    </SurveyProvider>
  );
}

export default App;
