// src/components/Footer.js
import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <p>&copy; {new Date().getFullYear()} Bedrock Compliance. All rights reserved.</p>
      {/* Add social media links or additional information */}
    </footer>
  );
}

export default Footer;
