// src/components/HubSpotFormModal.js
import React, { useEffect, useContext } from 'react';
import './HubSpotFormModal.css';
import { SurveyContext } from '../contexts/SurveyContext';

function HubSpotFormModal({ isOpen, onClose }) {
  const { submitSurvey } = useContext(SurveyContext);

  useEffect(() => {
    if (isOpen) {
      // Prevent background from scrolling
      document.body.classList.add('modal-open');

      // Load HubSpot's script
      const script = document.createElement('script');
      script.src = '//js.hsforms.net/forms/embed/v2.js';
      script.async = true; // Load asynchronously
      document.body.appendChild(script);

      script.onload = () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "eu1",
            portalId: "145406701",
            formId: "d23b8515-5251-4659-bd08-d86afb0c464c",
            target: '#hubspotForm',
            onFormSubmitted: () => {
              submitSurvey(); // Update the context
              onClose(); // Close the modal
            }
          });
        }
      };

      // Handle Esc key to close modal
      const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
          onClose();
        }
      };
      window.addEventListener('keydown', handleKeyDown);

      // Cleanup function to remove script and form when modal closes
      return () => {
        document.body.classList.remove('modal-open');
        window.removeEventListener('keydown', handleKeyDown);
        const hubspotForm = document.getElementById('hubspotForm');
        if (hubspotForm) {
          hubspotForm.innerHTML = '';
        }
      };
    }
  }, [isOpen, onClose, submitSurvey]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose} aria-modal="true" role="dialog">
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose} aria-label="Close Modal">Close</button>
        <div id="hubspotForm"></div>
      </div>
    </div>
  );
}

export default HubSpotFormModal;
