// firebase.js
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyASDpijF_VBVYDX34XAdSCOTtdUDMc-EQE",
  authDomain: "bedrockcompliance.firebaseapp.com",
  projectId: "bedrockcompliance",
  storageBucket: "bedrockcompliance.appspot.com",
  messagingSenderId: "101304741557",
  appId: "1:101304741557:web:b42746352ef85b3bf2d8fc",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize App Check and export the App Check instance
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LfenEMqAAAAALn7ylgsNF-VRZ4GA4ZAZS7YvqT1"), // Replace with your reCAPTCHA v3 site key
  isTokenAutoRefreshEnabled: true, // Enables automatic token refreshing
});

export { app, appCheck };
