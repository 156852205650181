// src/components/ProtectedRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { SurveyContext } from '../contexts/SurveyContext';

const ProtectedRoute = ({ children }) => {
  const { hasSubmittedSurvey } = useContext(SurveyContext);

  if (!hasSubmittedSurvey) {
    // Redirect to the Landing Page if survey not submitted
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
