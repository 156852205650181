import React, { useState } from 'react';
import axios from 'axios';
import { getToken } from 'firebase/app-check'; // Only import getToken
import './FreeTool.css';
import { appCheck } from '../firebase';

const FreeTool = () => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setAnswer('');

    try {
      // Retrieve the App Check token
      const tokenResponse = await getToken(appCheck, { forceRefresh: false });
      const appCheckToken = tokenResponse.token;

      // Make the API request with App Check token in headers
      const response = await axios.post(
        'https://us-central1-bedrockcompliance.cloudfunctions.net/api/ask', // Ensure this URL is correct
        { question: question },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Firebase-AppCheck': appCheckToken, // Include App Check token
          },
        }
      );

      // Set the response in the state
      setAnswer(response.data.answer);
    } catch (err) {
      // Improved error handling
      console.error('Error making the API request:', err.response || err.message || err);
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError('Error processing your request.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="free-tool-container">
      <h2>AI-Powered Regulatory Compliance Q&A Tool</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="question">Your Question:</label>
        <textarea
          id="question" // Added id attribute
          name="question" // Added name attribute
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Enter your compliance question here..."
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Processing...' : 'Get Answer'}
        </button>
      </form>
      {error && <p className="error">{error}</p>}
      {answer && (
        <div className="answer-section">
          <h3>Answer:</h3>
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

export default FreeTool;
