// src/contexts/SurveyContext.js
import React, { createContext, useState, useEffect } from 'react';

export const SurveyContext = createContext();

export const SurveyProvider = ({ children }) => {
  const [hasSubmittedSurvey, setHasSubmittedSurvey] = useState(false);

  // Initialize the survey status from localStorage
  useEffect(() => {
    const storedStatus = localStorage.getItem('hasSubmittedSurvey');
    if (storedStatus === 'true') {
      setHasSubmittedSurvey(true);
    }
  }, []);

  // Function to mark the survey as submitted
  const submitSurvey = () => {
    setHasSubmittedSurvey(true);
    localStorage.setItem('hasSubmittedSurvey', 'true');
  };

  // Optional: Function to reset the survey status (useful for testing)
  const resetSurvey = () => {
    setHasSubmittedSurvey(false);
    localStorage.removeItem('hasSubmittedSurvey');
  };

  return (
    <SurveyContext.Provider value={{ hasSubmittedSurvey, submitSurvey, resetSurvey }}>
      {children}
    </SurveyContext.Provider>
  );
};
